import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { jwtDecode } from "jwt-decode";
import { AuthContext } from "../../hoc/AuthProvider";

const Sertification = () => {
  const navigate = useNavigate();
  const userData = useContext(AuthContext);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    organization: "",
    fio: "",
    client_email: "",
  });
  const [punkt, setPunkt] = useState(null);

  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    const token = userData?.auth?.accessToken;
    if (token) {
      const decoded = jwtDecode(token);
      const { name, email, org } = decoded;
      setFormData({
        organization: org || "",
        fio: name || "",
        client_email: email || "",
      });
    }
  }, [userData]);

  const handleClick = (punktId) => {
    setPunkt(punktId);
    setModalOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const sendRequest = async (data) => {
    try {
      const response = await fetch(
        "https://settings3.copartner.ru/api/messages/ngCertifHandler",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        setOpen(true);
      } else {
        console.error("Ошибка при отправке заявки");
      }
    } catch (error) {
      console.error("Ошибка сети:", error);
    }
  };

  const handleModalSubmit = () => {
    setModalOpen(false);
    sendRequest({ ...formData, punkt });
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const textData = [
    {
      title:`Технологический аудит по специальным условиям для участников Ассоциации Металлообрабатывающих Предприятий `,
      text:`Проведем профессиональный технологический аудит и выявим производственные резервы вашего предприятия. Разработаем план быстрого наращивания объёма производства без инвестиций в основное оборудование. Первые заказчики могут получить услугу бесплатно! `
    },
    {title:`Специальная версия программы "АСУ Менеджмент" со скидкой 30% для участников Ассоциации Металлообрабатывающих Предприятий. `,
      text:`"АСУ Менеджмент" готовая ERP система для заводов металлоконструкций, цифровизации проектов строительно-монтажных работ и поставок. Участники Ассоциации имеющие опыт работы с системой "АСУ Менеджмент" по достоинству оценили возможности программы и рекомендуют использовать её всем, кто хочет навести порядок с предварительным расчётом стоимости, планированием и мониторингом выпуска металлоконструкций, включая работу с договорами и материалами.`
    },
    {
      title:`Бесплатный энергоаудит предприятия. `,
      text:`Только для  участников Ассоциации Металлообрабатывающих Предприятий проведем анализ действующих тарифов и платежей за электроэнергию. Из опыта, после аудита стоимость поставляемой для предприятия электроэнергии может быть снижена до 30 %. Это касается также и ранее произведенных платежей, переплату по которым, в случае выявления поможем вернуть. `
    },
    {
      title:`Подбор программ и источников финансирования. `,
      text:`Ставки по кредитам сейчас зашкаливают и даже по этим ставкам кредитоваться в банках почти невозможно. Но, для участников Ассоциации Металлообрабатывающих Предприятий, мы готовы проанализировать возможность альтернативного финансирования через госпрограммы и иные способы заимствования. `
    },
    {
      title:`Скидки на станки, другое оборудование, СОЖ и другие материалы. `,
      text:`Также  напоминаем, что многие поставщики оборудования и материалов сотрудничают с Ассоциацией Металлообрабатывающих Предприятий и специально для наших участников предоставляют существенные преференции и скидки. Прежде, чем принимать решение о закупках, направьте запрос в Ассоциацию для того, чтобы получить специальные партнерские предложения.`
    }
  ]
  return (
    <>
      <main className="main p-4 max-w-2xl md:max-w-3xl lg:px-0 lg:max-w-4xl xl:max-w-5xl 2xl:max-w-7xl mx-auto pb-8 w-full">
        <div className="header mb-8">
          <button
            onClick={handleBackClick}
            className="bg-teal-500 text-white rounded px-8 py-3 text-lg"
            style={{
              background: "rgb(0, 174, 174)",
              borderRadius: "5px",
              padding: "10px 30px",
              fontSize: "30px",
              color: "rgb(255, 255, 255)",
              margin: "30px 0",
            }}
          >
            Назад
          </button>
        </div>

        {textData.map((textD, index) => (
          <section key={index} className="mb-6 p-4 bg-white rounded shadow">
            <p className="text-2xl font-semibold text-black font-bold py-4">{textD?.title}</p>
            <p className="text-lg font-semibold text-gray-600 py-2">{textD?.text}</p>
            <div className="flex justify-end px-4">
              <p className="text-sm mt-2" style={{ color: "rgb(232, 37, 5)" }}>* Обращайтесь, количество сертификатов ограничено. </p>
              <button
                className="ml-2 px-4 py-2 text-white rounded hover:bg-teal-600"
                style={{ background: "rgb(0, 174, 174)" }}
                onClick={() => handleClick(index + 1)}
              >
                Отправить заявку</button>
            </div>
          </section>
        ))}
        {/* <p className="text-lg font-semibold text-gray-800">Предоставляем скидки на станки и другое оборудование.</p> */}
        {/* Snackbar */}
        <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
            Заявка отправлена
          </Alert>
        </Snackbar>

        {/* Модальное окно */}
        {modalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded shadow-lg relative">
              <button
                className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
                onClick={handleModalClose}
              >
                ✖
              </button>
              <h2 className="text-lg font-semibold mb-4 text-black">
                Заполните данные для отправки заявки
              </h2>
              <form>
                <div className="mb-4 text-black">
                  <label className="block text-sm font-medium mb-1 text-black">
                    Организация
                  </label>
                  <input
                    type="text"
                    name="organization"
                    value={formData.organization}
                    onChange={handleInputChange}
                    className="w-full border border-gray-300 rounded px-3 py-2"
                  />
                </div>
                <div className="mb-4 text-black">
                  <label className="block text-sm font-medium mb-1 text-black">
                    ФИО
                  </label>
                  <input
                    type="text"
                    name="fio"
                    value={formData.fio}
                    onChange={handleInputChange}
                    className="w-full border border-gray-300 rounded px-3 py-2"
                  />
                </div>
                <div className="mb-4 text-black">
                  <label className="block text-sm font-medium mb-1 text-black">
                    Email
                  </label>
                  <input
                    type="email"
                    name="client_email"
                    value={formData.client_email}
                    onChange={handleInputChange}
                    className="w-full border border-gray-300 rounded px-3 py-2"
                  />
                </div>
                <div className="flex justify-between">
                  <button
                    type="button"
                    onClick={handleModalClose}
                    className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                  >
                    Отмена
                  </button>
                  <button
                    type="button"
                    onClick={handleModalSubmit}
                    className="px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600"
                  >
                    Отправить
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </main>
    </>
  );
};

export default Sertification;
